exports.components = {
  "component---src-pages-balkoniki-francuskie-tsx": () => import("./../../../src/pages/balkoniki-francuskie.tsx" /* webpackChunkName: "component---src-pages-balkoniki-francuskie-tsx" */),
  "component---src-pages-balustrady-tarasowe-tsx": () => import("./../../../src/pages/balustrady-tarasowe.tsx" /* webpackChunkName: "component---src-pages-balustrady-tarasowe-tsx" */),
  "component---src-pages-errors-404-tsx": () => import("./../../../src/pages/errors/404.tsx" /* webpackChunkName: "component---src-pages-errors-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informacje-tsx": () => import("./../../../src/pages/informacje.tsx" /* webpackChunkName: "component---src-pages-informacje-tsx" */),
  "component---src-pages-konstrukcje-stalowe-tsx": () => import("./../../../src/pages/konstrukcje-stalowe.tsx" /* webpackChunkName: "component---src-pages-konstrukcje-stalowe-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-porecze-schodowe-tsx": () => import("./../../../src/pages/porecze-schodowe.tsx" /* webpackChunkName: "component---src-pages-porecze-schodowe-tsx" */)
}

