import { extendTheme, Theme } from '@chakra-ui/react';

interface MyColors {
    primary: string;
    secondary: string;
    background: string;
    border: string;
}

interface MyTheme extends Theme {
    brandColors: MyColors;
}

const colorPrimary = '#1A1B38';
const colorSecondary = '#F7A917';

export const theme = extendTheme({
    breakpoints: {
        'sm': '900px', // adjusted so the transition is more or less fluid
        'md': '980px',
        'lg': '1060px',
        'xl': '1200px',
        '2xl': '1536px',
    },
    components: {
        Text: {
            baseStyle: { color: colorPrimary, fontFamily: 'sans-serif' },
            variants: { secondary: { color: colorSecondary } },
        },
        Heading: {
            baseStyle: { color: colorPrimary, fontFamily: 'Montserrat, sans-serif' },
            variants: { secondary: { color: colorSecondary } },
        },
    },
    brandColors: {
        primary: colorPrimary,
        secondary: colorSecondary,
        background: '#F8F8FC',
        border: '#DCDDEF',
    },
}) as MyTheme;
export default theme;
